

import CSS from 'csstype';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    item:{
      '&:hover':{
        boxShadow: '0.1875rem 0.1875rem 0.625rem black',
        transform: 'translate(2px, -2px)'
      }
    },
    white: {
      fill: '#fff'
    },
    blue: {
      fill: '#78c6ea'
    }
  })
);



interface ITwitterCircleIcon {
  // fillColor: string;
  styles?: CSS.Properties;
  link: string
}

export const TwitterCircleIcon: React.FC<ITwitterCircleIcon> = ({ styles, link }) => {

  const classes = useStyles();

  return (
    <a href={link} target='_blank' rel='noreferrer' style={{lineHeight: 0}} className={classes.item}>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer', ...styles }} viewBox="0 0 20 20"><defs></defs><g transform="translate(-1046.8 -86.1)"><path className={classes.blue} d="M1056.8,106.1a10,10,0,1,0-10-10,10.029,10.029,0,0,0,10,10" /><path className={classes.white} d="M1061.9,93.4a2.663,2.663,0,0,1-1.2.3,2.326,2.326,0,0,0,.9-1.1,5.024,5.024,0,0,1-1.3.5,2.035,2.035,0,0,0-1.5-.6,2.112,2.112,0,0,0-2.1,2.1.9.9,0,0,0,.1.5,5.908,5.908,0,0,1-4.2-2.1,1.93,1.93,0,0,0-.3,1,2.227,2.227,0,0,0,.9,1.7,1.4,1.4,0,0,1-.9-.3h0a2.043,2.043,0,0,0,1.6,2,4.332,4.332,0,0,0-.5.1h-.4a2.044,2.044,0,0,0,1.9,1.4,3.916,3.916,0,0,1-2.6.9h-.5a5.875,5.875,0,0,0,9-4.9v-.3a6.283,6.283,0,0,0,1.1-1.2" /></g></svg>


    </a>

  )

}