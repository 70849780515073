
import React from 'react';
import styles from './MintButton.module.css';


export default function MintButton({ text, onClick }: { text: string, onClick: any }) {

  return (
    <div className={styles['mint-button']} onClick={onClick}>
      {text}
    </div>
  )

}