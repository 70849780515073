
import { useCallback } from 'react';
import { Dispatch, SetStateAction } from 'react';
import styles from './MintIncrementor.module.css';



interface IMintIncrementor {
  number: number;
  setNumber: Dispatch<SetStateAction<number>>;
  max: number
}

export default function MintIncrementor({number, setNumber, max}: IMintIncrementor) {

  const increase = useCallback(() => {
    if(number + 1 > max) return;
    setNumber((prev: number) => prev + 1);
  }, [max, number, setNumber]);

  const decrease = () => {
    if(number - 1 <= 0) return;
    setNumber((prev: number) => prev - 1);
  }

  return (


      <>
        <div className={styles['container']}>

          <div className={styles['button-item']} onClick={decrease}>
          <span className={styles['minus']}>-</span>
          </div>

          <div className={styles['cur-number']}>
            {number}
          </div>

          <div className={styles['button-item']} onClick={increase}>
          <span className={styles['plus']}>+</span>
          </div>

        </div>

        <div className={styles['price-container']}>
          <span className={styles['price']}>{(number * 0.02).toFixed(2)} ETH</span>
        </div>
      </>
  )

}