
import styles from './ConnectWalletState.module.css';
import { useWallet } from 'hooks/useWallet';
import MintButton from 'components/MintButtons/MintButton';

export default function ConnectWalletState() {

  const { connectWallet } = useWallet();

  return (
    <div className={styles['container']}>

      <h3>Please Connect your Wallet</h3>

      <div className={styles['button-container']}>
        <MintButton
          text={'CONNECT WALLET'}
          onClick={connectWallet}
        />
      </div>
    </div>
  )
}
