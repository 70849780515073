
import { useEffect, } from "react";


/**
 * Hook that alerts clicks outside of the passed ref
 */
 export function useClickOutside(ref: any, callBack: any) {
  useEffect(() => {

    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callBack();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callBack]);
}
