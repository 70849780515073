import CSS from 'csstype';

interface IDiscordIcon {
  fillColor: string;
  styles: CSS.Properties;
  onClick: any;
}



export const Hamburger: React.FC<IDiscordIcon> = ({ fillColor, onClick, styles }) => {

  return (
    <svg viewBox="0 0 100 80" onClick={onClick} fill={fillColor} style={styles}>
      <rect width="100" height="20"></rect>
      <rect y="30" width="100" height="20"></rect>
      <rect y="60" width="100" height="20"></rect>
    </svg>
  )

}