import React from 'react'
import styles from './MastheadCopy.module.css';
import Dot from 'media/dot.jpg';


export default function MastheadCopy() {
  return (
    <div className={styles['header-copy-container']}>

      <p className={styles['header-copy']}>
        The Prosocialites are a collection of 7,000 vibrant and diverse NFTs taking action to showcase the world-changing power of digital communities.
      </p>

      <br></br>

      <p className={styles['header-copy']}>
        Your Prosocialites PFP:
      </p>

      <div className={styles['list-item']}>
        <img src={Dot} className={styles['dot']} alt={'dot'} />
        <p className={styles['header-copy']}>
          Generates <a href={'https://twitter.com/etchedNFT/status/1549847959574941696'} className={styles['link']} target={'_blank'} rel='noreferrer'>$1 / day for charity</a>
        </p>
      </div>

      <div className={styles['list-item']}>
        <img src={Dot} className={styles['dot']} alt={'dot'} />
        <p className={styles['header-copy']}>
          Governs the <a href={'https://app.endaoment.org/prosocialites'} className={styles['link']} target={'_blank'} rel='noreferrer'>Etched Community Fund</a>
        </p>
      </div>


      <div className={styles['list-item']}>
        <img src={Dot} className={styles['dot']} alt={'dot'} />
        <p className={styles['header-copy']}>
          Earns 100% royalties through its <a href={'https://madeinvsa.com/prosocialites'} className={styles['link']} target={'_blank'} rel='noreferrer'>custom storefront</a> and a lot more!
        </p>
      </div>

      <br></br>

      <p className={styles['header-copy']}>
        Artwork by Emmy Award-winning artists Stephen Slesinski and Courtney Hicks with full ownership and commercial usage rights granted. <a href={'https://drive.google.com/file/d/1LRRghqYQraxgINSRu55HmKuKJLdcKnds/view'} className={styles['link']} target={'_blank'} rel='noreferrer'>Read our whitepaper</a>.
      </p>
    </div>


  )
}
