
import React from 'react';
import styles from './MastheadImage.module.css';
import MastheadAnimation from 'media/masthead-animation.gif';
// import MastheadAnimation from 'media/masthead.jpg';


export default function MastheadImage() {
  return (
    <div className={styles['header-image-container']}>
      <img
        src={MastheadAnimation}
        className={styles['header-image']}
        alt={'Prosocialites'}
      />
      {/* <h3 className={styles['tagline']}>A <span className={styles['white']}>prosocial</span> web3 community</h3> */}
    </div>
  )
}
