


import React from 'react'
import DesktopMenu from './DesktopMenu/DesktopMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import styles from './Menu.module.css';

export default function Menu() {
  return (
    <>
      <span className={styles['mobile']}><MobileMenu/></span>
      <span className={styles['desktop']}><DesktopMenu/></span>
    </>
  )
}
