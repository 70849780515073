
import React from 'react';
import styles from './RoadMapProgress.module.css';

import FlickerOn from 'media/flicker-on.gif';




const RoadMapProgress = React.forwardRef(({curTop}: {curTop: number}, ref) => {
  return (
    <div className={styles['roadmap-progress-wrapper']}>
      <div className={styles['roadmap-progress-line']}></div>
      <div 
        className={styles['roadmap-image-wrapper']} 
        ref={ref as React.RefObject<HTMLDivElement>}
        style={{top: curTop + 'px'}}
      >
        <img src={FlickerOn} className={styles['roadmap-image']} alt={'PROSOCIALITES'} />
      </div>
    </div>
  );
});


export default RoadMapProgress;




// export default function RoadMapProgress({itemOneRef}: {itemOneRef: any}) {


//   console.log('item ', itemOneRef);


//   return (
//     <div className={styles['roadmap-progress-wrapper']}>
//       <div className={styles['roadmap-progress-line']}></div>
//       <div className={styles['roadmap-image-wrapper']}>
//         <img src={FlickerOn} className={styles['roadmap-image']} alt={'PROSOCIALITES'} />
//       </div>
//     </div>
//   )
// }
