
import { useWallet } from 'hooks/useWallet';
import { useEffect } from 'react';


export const Withdraw = (): JSX.Element => {

  const {
    state,
  } = useWallet();

  const { address, contract } = state


  useEffect(()=>{

    if(!address || !contract) return;

    const runWithdraw = async () => {
      let run = await contract.withdraw(      {
        gasLimit: 200000,
      });
    }

    runWithdraw();

  }, [address, contract])


  return(
    <></>
  )

}

export default Withdraw