import React from 'react';
import styles from './Footer.module.css';
import Background from 'components/Background/Background';
import EtchedLogo from 'media/etched.jpg';
import { YoutubeIcon } from 'icons/YoutubeIcon';
import { OpenSeaIcon } from 'icons/OpenSeaIcon';

export default function Footer() {
  return (
    <div className={styles['footer']}>
      <div className={styles['footer-container'] + ' container'}>
        <div className={styles['footer-row']}>

          <div className={styles['left']}>
            <OpenSeaIcon
              link={'https://opensea.io/collection/etchedcollections'}
              fillColor={'#2081E2'}
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                margin: '0 10px'
              }}
            />
            <YoutubeIcon
              fillColor={'#FF0000'}
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '45px', height: '40px',
                margin: '0 5px'
              }}
              link={'https://www.youtube.com/channel/UCQjMV3g25uWdsEh1LKv9uCA/featured'}
            />
          </div>

          <div className={styles['divider']}></div>

          <div className={styles['center']}>

            <div className={styles['center-column']}>
              <div className={styles['center-item-container']}><a className={styles['center-item']} href={'/'}>MINT</a></div>
              <div className={styles['center-item-container']}><a className={styles['center-item']} href={'#roadmap'}>ROADMAP</a></div>
              <div className={styles['center-item-container']}><a className={styles['center-item']} href={'#faq'}>FAQ</a></div>
            </div>

            <div className={styles['center-column']}>
              <div className={styles['center-item-container']}><a className={styles['center-item']} href={'https://discord.com/invite/etched'} target={'_blank'} rel="noreferrer">DISCORD</a></div>
              <div className={styles['center-item-container']}><a className={styles['center-item']} href={'https://twitter.com/etchedNFT'} target={'_blank'} rel="noreferrer">TWITTER</a></div>
              <div className={styles['center-item-container']}><a className={styles['center-item']} href={'mailto:info@etched.co'}>CONTACT</a></div>
            </div>

          </div>


          <div className={styles['divider']}></div>

          <div className={styles['right']}>
            <h5 className={styles['powered-by']}>Powered By</h5><img src={EtchedLogo} className={styles['etched-logo']} alt={'Etched'} />
            <h4 className={styles['rights']}>©2022 Etched, Inc. All rights reserved.</h4>
          </div>


        </div>
      </div>
      <Background />
    </div>
  )
}