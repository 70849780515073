import { useEffect, useState } from 'react';
import { useWallet } from 'hooks/useWallet';

//Need to add loading here
export default function useCollectionItems() {
  const { state } = useWallet();
  const { address } = state;

  const [itemsOwned, setItemsOwned] = useState([]);

  useEffect(() => {
    if (!address) return;

    const options = { method: 'GET', headers: { Accept: 'application/json' } };

    fetch(
      process.env.REACT_APP_OS_API_URL +
        '/assets?owner=' +
        address +
        '&order_direction=asc&asset_contract_addresses=' +
        process.env.REACT_APP_CONTRACT_ADDRESS +
        '&limit=50&include_orders=false',
      options
    )
      .then((response) => response.json())
      .then((response) => {
        if (response?.assets) setItemsOwned(response.assets);
      })
      .catch((err) => console.error(err));
  }, [address]);

  return {
    itemsOwned,
  };
}
