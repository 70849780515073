import React from 'react';
import styles from './Team.module.css';

import SectionContainer from 'containers/SectionContainer/SectionContainer';
import SectionHeader from 'components/SectionHeader/SectionHeader';

import TeamTitle from 'media/team.jpg';
import TeamMemberItem from 'components/TeamMemberItem/TeamMemberItem';

import Brett from 'media/team/brett-2.jpg';
import Courtney from 'media/team/courtney-2.jpg';
import Ediz from 'media/team/ediz-2.jpg';
import Girldad from 'media/team/girldad-2.png';
import Linum from 'media/team/linum.jpg';
import Michael from 'media/team/michael-2.jpg';
import Shadowlion from 'media/team/shadowlion.jpg';
import Stephen from 'media/team/stephen-2.jpg';
import SpicyMan from 'media/team/spicyman.png';


export default function Team() {
  return (
    <SectionContainer id={'team'}>
      <SectionHeader
        image={TeamTitle}
        alt={'Team Members'}
        imageStyle={{ maxWidth: '500px' }}
      />

      <div className={styles['team-member-row']}>
        <TeamMemberItem
          name={'Brett Memsic'}
          title={'Co-founder'}
          linkedIn={'https://www.linkedin.com/in/memsic/'}
          twitter={'https://twitter.com/memsic'}
          calendly={'https://calendly.com/memsic/15min'}
          image={Brett}
        />

        <TeamMemberItem
          name={'Ediz Basol'}
          title={'Co-founder'}
          linkedIn={'https://www.linkedin.com/in/edizbasol/'}
          calendly={'https://calendly.com/edizb'}
          image={Ediz}
        />

        <TeamMemberItem
          name={'Girldad'}
          title={'Partnerships & Marketing'}
          twitter={'https://twitter.com/girldadNFT'}
          image={Girldad}
        />

        <TeamMemberItem
          name={'Spicy Man'}
          title={'Programming Advisor'}
          twitter={'https://twitter.com/SpicyMan117'}
          image={SpicyMan}
          noGlow={true}
        />

        <TeamMemberItem
          name={'Michael Lingenfelter'}
          title={'Community Director'}
          twitter={'https://twitter.com/mlingy86'}
          image={Michael}
        />

        <TeamMemberItem
          name={'Stephen Slesinski'}
          title={'Art Director'}
          twitter={'https://twitter.com/im_draw'}
          generalLink={'https://www.artofstephens.com/'}
          image={Stephen}
        />

        <TeamMemberItem
          name={'Courtney Hicks'}
          title={'Art Director'}
          instagram={'https://www.instagram.com/courtney.e.hicks/?hl=en'}
          generalLink={'http://www.courtneyhicksillustration.com/'}
          image={Courtney}
        />

        <TeamMemberItem
          name={'Linum Labs'}
          title={'Solidity'}
          twitter={'https://twitter.com/linumlabs?lang=en'}
          generalLink={'https://www.linumlabs.com/'}
          image={Linum}
        />

        <TeamMemberItem
          name={'Shadow Lion'}
          title={'Creative Agency'}
          instagram={'https://www.instagram.com/shadowlion/?hl=en'}
          generalLink={'https://www.shadowlion.com/'}
          image={Shadowlion}
        />
      </div>
    </SectionContainer>
  );
}
