import { useCallback, useEffect, useReducer, createContext } from 'react';
import { providers } from 'ethers';
import { ethers } from 'ethers'

import WalletReducer, { initialState } from 'store/WalletReducer';

import EtchedContractAbi from 'web3/EtchedABI.json';
import web3Modal from 'web3/Web3Modal';


interface IWalletContext {

}

export const WalletContext = createContext<any>({} as IWalletContext);

export const WalletProvider = ({ children }: { children: JSX.Element }) => {

  const [state, dispatch] = useReducer(WalletReducer, initialState);

  const { provider, } = state

  const connectWallet = useCallback(async function () {

    try{
      // This is the initial `provider` that is returned when
      // using web3Modal to connect. Can be MetaMask or WalletConnect.
      const provider = await web3Modal.connect();
  
      // We plug the initial `provider` into ethers.js and get back
      // a Web3Provider. This will add on methods from ethers.js and
      // event listeners such as `.on()` will be different.
      const web3Provider = new providers.Web3Provider(provider);
  
      const signer = web3Provider.getSigner();
      const address = await signer.getAddress();
      const network = await web3Provider.getNetwork();
  
      const contractAddress: any = process.env.REACT_APP_CONTRACT_ADDRESS;
  
      // const nftContract = new web3.eth.Contract(contract.abi, contractAddress)
      let contract = new ethers.Contract(contractAddress, EtchedContractAbi, signer);
    
      dispatch({
        type: 'SET_WEB3_PROVIDER',
        provider,
        web3Provider,
        address,
        networkInfo: {
          chainId: network.chainId,
          networkName: network.name,
        },
        contract,
      })
    } catch(error){}

  }, [])

  const disconnectWallet = useCallback(
    async function () {
      await web3Modal.clearCachedProvider()
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }
      dispatch({
        type: 'RESET_WEB3_PROVIDER',
      })
    },
    [provider]
  )

  // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet()
    }
  }, [connectWallet])

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        window.location.reload(); 
        dispatch({
          type: 'SET_ADDRESS',
          address: accounts[0],
        })
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        window.location.reload(); 
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        disconnectWallet()
      }

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnectWallet]);



    //set the number of nfts already sold
    useEffect(()=>{

      const getTotalSupply = async () => {

        const contractAddress: any = process.env.REACT_APP_CONTRACT_ADDRESS;

        const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC);

        let contract = new ethers.Contract(contractAddress, EtchedContractAbi, provider );

        let res = await contract.totalSupply();



        if (res?._hex){
          dispatch({
            type: 'SET_NUMBER_MINTED',
            numMinted: parseInt(res._hex, 16),
          })
        }
      }

      getTotalSupply();
  
    }, []);




  return (
    <WalletContext.Provider value={{
      connectWallet,
      disconnectWallet,
      state, 
      dispatch
    }}>
      {children}
    </WalletContext.Provider>
  )

}