import React, { useRef } from 'react'
import styles from './RoadMapItem.module.css';
import useLightUp from 'hooks/useLightUp';

interface IRoadMapItem {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  isLit: boolean;
  setIsLit: any
}

const RoadMapItem = ({ title, subtitle, children, isLit, setIsLit }: IRoadMapItem) => {

  const itemRef = useRef<HTMLInputElement>(null);

  useLightUp(itemRef, setIsLit);

  return (
    <div className={styles['container']}>

      <div className={
        styles['road-map-item'] +
        ' ' +
        (isLit ? styles['lights-on'] : '')
      } ref={itemRef}>

        <div className={styles['flicker-container']}>
          {/* {!isLit ?
            <img src={Flicker} className={styles['flicker']} alt={'PROSOCIALITES'} />
            :
            <img src={FlickerOn} className={styles['flicker']} alt={'PROSOCIALITES'} />
          } */}
        </div>

        <div className={styles['text-section']}>
          <h3 className={styles['title']}>{title}</h3>
          <h3 className={styles['subtitle']}>{subtitle}</h3>
          <div className={styles['content-container']}>
            {children}
          </div>
        </div>


        <div className={styles['image-section']}>
          <div className={styles['image-container']}>
            <div className={styles['image-placeholder']}></div>
          </div>
        </div>


      </div>

    </div>

  )
};


export default RoadMapItem;

// export default function RoadMapItem({ title, subtitle, children }: IRoadMapItem) {

//   const itemRef = useRef<HTMLInputElement>(null);

//   const { isLit } = useLightUp(itemRef);

//   return (
//     <div className={styles['container']}>

//       <div className={
//         styles['road-map-item'] +
//         ' ' +
//         (isLit ? styles['lights-on'] : '')
//       } ref={itemRef}>


//         <div className={styles['target']} ref={ref}></div>

//         <div className={styles['flicker-container']}>
//           {/* {!isLit ?
//             <img src={Flicker} className={styles['flicker']} alt={'PROSOCIALITES'} />
//             :
//             <img src={FlickerOn} className={styles['flicker']} alt={'PROSOCIALITES'} />
//           } */}
//         </div>

//         <div className={styles['text-section']}>
//           <h3 className={styles['title']}>{title}</h3>
//           <h3 className={styles['subtitle']}>{subtitle}</h3>
//           <div className={styles['content-container']}>
//             {children}
//           </div>
//         </div>


//         <div className={styles['image-section']}>
//           <div className={styles['image-container']}>
//             <div className={styles['image-placeholder']}></div>
//           </div>
//         </div>



//         {/* <div className={styles['connector']}></div> */}
//       </div>

//     </div>
//   )
// }
