import { useEffect, useState } from 'react';
import { MerkleTree } from 'merkletreejs';
import { ethers } from 'ethers';

import phaseOneData from 'data/PhaseOneData';
import phaseTwoData from 'data/PhaseTwoData';
import keccak256 from 'keccak256';

export default function useMerkleTree() {
  const [phaseOneTree, setPhaseOneTree] = useState<any>({
    tree: null,
    root: null,
  });
  const [phaseTwoTree, setPhaseTwoTree] = useState<any>({
    treeTwo: null,
    rootTwo: null,
  });

  const hashDataPhaseOne = (addr: string, amount: number) => {
    return ethers.utils
      .solidityKeccak256(['address', 'uint256'], [addr, amount])
      .slice(2);
  };

  const hashDataPhaseTwo = (address: string) => {
    return keccak256(address);
  };


  useEffect(() => {

    let leaves = Object.keys(phaseOneData).map((key: any) => {
      return hashDataPhaseOne(key, phaseOneData[key]);
    });

    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true });
    const root = tree.getRoot().toString('hex');

    // console.log('treeOne ', tree.getHexRoot());

    setPhaseOneTree({
      tree,
      root,
    });

    ////////////////////////////////////////////////////////////////////////////////////
    // PHASE 2 TREE

    let leavesTwo = phaseTwoData.map((address: string) => {
      return hashDataPhaseTwo(address);
    });

    const treeTwo = new MerkleTree(leavesTwo, keccak256, { sortPairs: true });
    const rootTwo = treeTwo.getRoot().toString('hex');

    setPhaseTwoTree({
      treeTwo,
      rootTwo,
    });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    phaseOneTree,
    phaseTwoTree,

    hashDataPhaseOne,
    hashDataPhaseTwo,
  };
}
