import styles from './Mint.module.css';
import { useCallback, useState } from 'react';
import { ethers } from 'ethers';
// components
import MastheadImage from 'components/MastheadImage/MastheadImage';
import ConnectWalletState from 'components/Minting/ConnectWalletState/ConnectWalletState';
import TotalMintCounter from 'components/Minting/TotalMintCounter/TotalMintCounter';
import MintButtonGif from 'components/MintButtons/MintButtonGif';
import SoldOutButton from 'components/MintButtons/SoldOutButton';
import MintIncrementor from 'components/Minting/MintIncrementor/MintIncrementor';
// hooks
import useMerkleTree from 'hooks/useMerkleTree';
import useCanMintPhases from 'hooks/useCanMintPhases';
import { useWallet } from 'hooks/useWallet';
// images
import ArtExample from 'media/art-large.gif';
import { Loader } from 'components/Loader/Loader';

const MINT_PRICE = (process.env.REACT_APP_MINT_PRICE ? 
  parseFloat(process.env.REACT_APP_MINT_PRICE): 0.02);


export const WalletConnect = (): JSX.Element => {

  const {
    state,
  } = useWallet();

  const { address, networkInfo, contract, isLoading, numMinted } = state
  const {
    canMintPhaseOne,
    canMintPhaseTwo,
    canMintPublic,
    alreadyMintPhaseOne,
    phaseFlags
  } = useCanMintPhases();


  const {
    phaseOneTree,
    phaseTwoTree,
    hashDataPhaseOne,
    hashDataPhaseTwo,
  } = useMerkleTree();


  const [numberToMint, setNumberToMint] = useState(1);
  const [justMinted, setJustMinted] = useState<boolean | string>(false);


  /**
   * Mint for phase one
   * Free mint, only people on list can get one
   */
  const phaseOneMint = useCallback(async () => {
    if (!contract || !address || !canMintPhaseOne) return;
    if (typeof canMintPhaseOne !== 'number') return; //type check

    let proof = phaseOneTree.tree.getHexProof(hashDataPhaseOne(address, canMintPhaseOne)); //get proof
    const res = await contract.phaseOneMint(
      ethers.BigNumber.from(canMintPhaseOne),
      proof,
      {
        gasLimit: 200000,
      }
    )

    if (res && res.hash) setJustMinted(res.hash);
    else setJustMinted(true); //if true then something failed

  }, [address, canMintPhaseOne, contract, hashDataPhaseOne, phaseOneTree.tree]);


  /**
   * Mint for phase 2
   * Paid mint only people on list can get
   * Max 5
   */
  const phaseTwoMint = useCallback(async () => {
    if (!contract || !address || !canMintPhaseTwo) return;

    let proof = phaseTwoTree.treeTwo.getHexProof(hashDataPhaseTwo(address)); //get proof
    const res = await contract.phaseTwoMint(
      ethers.BigNumber.from(numberToMint.toString()),
      proof,
      {
        value: ethers.utils.parseEther((numberToMint * MINT_PRICE).toString()),
        gasLimit: 200000,
      }
    );

    if (res && res.hash) setJustMinted(res.hash);
    else setJustMinted(true); //if true then something failed

  }, [address, canMintPhaseTwo, contract, hashDataPhaseTwo, numberToMint, phaseTwoTree.treeTwo]);


  /**
   * Standard Mint for regular users
   * Anyone can mint here 
   * 10 max
   */
  const publicMint = useCallback(async () => {

    if (!contract || !address || !canMintPublic) return;
    const res = await contract.mint(
      ethers.BigNumber.from(numberToMint.toString()), {
      value: ethers.utils.parseEther((numberToMint * MINT_PRICE).toString()),
      gasLimit: 200000,
    })

    if (res && res.hash) setJustMinted(res.hash);
    else setJustMinted(true); //if true then something failed

  }, [address, canMintPublic, contract, numberToMint])


  return (
    <>
      <div className={styles['main']} style={{ color: 'white' }}>

        <div className={styles['container']}>

          <MastheadImage />

          <div className={styles['page-row']}>

            <div className={styles['image-container']}>
              <img src={ArtExample} className={styles['image']} alt={'Prosocial Art'} />
              <TotalMintCounter numMinted={numMinted} />
            </div>

            <div className={styles['info-container']}>
              {phaseFlags.phaseThree && canMintPublic ?
                <></>
              :
                <div className={styles['dates-container']}>
                  <div className={styles['date-item']}>
                    Presale: May 2, 12pm PDT (0.02 ETH)
                  </div>
                  <div className={styles['date-item']}>
                    Public Sale: May 4, 12pm PDT (0.02 ETH)
                  </div>
                </div>
              }

              {numMinted >= 7000 ?
                <div className={styles['state-container']}>
                  <SoldOutButton />
                </div>
                : justMinted ?
                  <>
                    {typeof justMinted === 'boolean' ?
                      <div className={styles['state-container']}>
                        <h2>
                          Transaction failed. Please try again.
                        </h2>
                      </div>
                      :
                      <div className={styles['state-container']}>
                        <h2 style={{ marginBottom: '2px' }}>
                          Transaction started!
                        </h2>
                        <h2>
                          View on <a href={process.env.REACT_APP_ETHERSCAN_URL + '/tx/' + justMinted} target={'_blank'} rel='noreferrer'>Etherscan</a>
                        </h2>
                      </div>
                    }
                  </>
                  : !address ?
                    <ConnectWalletState />
                    : isLoading ?
                      <div className={styles['loading-container']}>
                        <Loader size={100} />
                      </div>
                      : process.env.REACT_APP_CORRECT_CHAIN_ID && networkInfo.chainId !== parseInt(process.env.REACT_APP_CORRECT_CHAIN_ID) ?
                        <div className={styles['state-container']}>
                          <h2 style={{ marginBottom: '2px' }}>
                            Please switch to Ethereum Mainnet.
                          </h2>
                          <h2>
                            Current network: <span className={styles['white']}>{networkInfo.networkName}</span>
                          </h2>
                        </div>
                        : !alreadyMintPhaseOne && phaseFlags.phaseOne && canMintPhaseOne ?
                          <div className={styles['state-container']}>
                            <h2>You are eligible for <span>{canMintPhaseOne}</span> free {canMintPhaseOne === 1 ? <>Mint</> : <>Mints</>}</h2>
                            <MintButtonGif
                              onClick={phaseOneMint}
                            />
                          </div>
                          : !alreadyMintPhaseOne && !phaseFlags.phaseOne && canMintPhaseOne ?
                            <div className={styles['state-container']}>
                              <h2>You are eligible for <span className={styles['white']}>{canMintPhaseOne}</span> free {canMintPhaseOne === 1 ? <>Mint</> : <>Mints</>}.</h2>
                              <h2>Minting will start soon.</h2>
                            </div>
                            : phaseFlags.phaseThree && canMintPublic ?
                              <div className={styles['state-container']}>
                                <h2>You are eligible for <span className={styles['white']}>{canMintPublic}</span> {canMintPublic === 1 ? <>Mint</> : <>Mints</>}.</h2>
                                <MintButtonGif
                                  onClick={publicMint}
                                />
                                <MintIncrementor
                                  number={numberToMint}
                                  setNumber={setNumberToMint}
                                  max={canMintPublic}
                                />
                              </div>
                              : phaseFlags.phaseThree && !canMintPublic ?
                                <div className={styles['state-container']}>
                                  <h2 className={styles['above-mint-button']}>You have already minted your maximum allotment.</h2>
                                </div>
                                : !phaseFlags.phaseTwo && canMintPhaseTwo ?
                                  <div className={styles['state-container']}>
                                    <h2>You are eligible for up to <span className={styles['white']}>{canMintPhaseTwo}</span> presale {canMintPhaseTwo === 1 ? <>Mint</> : <>Mints</>}.</h2>
                                  </div>
                                  : phaseFlags.phaseTwo && canMintPhaseTwo && canMintPhaseTwo > 0 ?
                                    <div className={styles['state-container']}>
                                      <h2 className={styles['above-mint-button']}>You are eligible for <span className={styles['white']}>{canMintPhaseTwo}</span> {canMintPhaseTwo === 1 ? <>Mint</> : <>Mints</>}.</h2>
                                      <MintButtonGif
                                        onClick={phaseTwoMint}
                                      />
                                      <MintIncrementor
                                        number={numberToMint}
                                        setNumber={setNumberToMint}
                                        max={canMintPhaseTwo}
                                      />
                                    </div>
                                    :
                                    <div className={styles['state-container']}>
                                      <h2>Please return for the public <br></br> phase of minting</h2>
                                    </div>
              }

            </div>

          </div>

        </div>

      </div>


    </>
  )
}





export default WalletConnect
