type StateType = {
  provider?: any;
  web3Provider?: any;
  address?: string | null | undefined;
  networkInfo: TNetworkInfo | undefined;
  contract?: any;
  isLoading: boolean;
  numMinted: number;
};

type TNetworkInfo = {
  chainId: number | null | undefined;
  networkName: string | undefined;
};

type ActionType =
  | {
      type: 'SET_WEB3_PROVIDER';
      provider?: StateType['provider'];
      web3Provider?: StateType['web3Provider'];
      address?: StateType['address'];
      contract?: StateType['contract'];
      networkInfo?: StateType['networkInfo'];
      isLoading?: StateType['isLoading'];
    }
  | {
      type: 'SET_ADDRESS';
      address?: StateType['address'];
    }
  | {
      type: 'SET_CONTRACT';
      contract: StateType['contract'];
      numMinted?: StateType['numMinted'];
    }
  | {
      type: 'SET_NUMBER_MINTED';
      numMinted: StateType['numMinted'];
    }
  | {
      type: 'SET_NETWORK_INFO';
      networkInfo: StateType['networkInfo'];
    }
  | {
      type: 'RESET_WEB3_PROVIDER';
    }
  | {
      type: 'SET_IS_LOADING';
      isLoading: StateType['isLoading'];
    };

export const initialState: StateType = {
  provider: null,
  web3Provider: null,
  address: null,
  networkInfo: {
    chainId: undefined,
    networkName: undefined,
  },
  contract: null,
  isLoading: true,
  numMinted: 0,
};

function WalletReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case 'SET_WEB3_PROVIDER':
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        networkInfo: action.networkInfo,
        contract: action.contract,
      };
    case 'SET_CONTRACT':
      return {
        ...state,
        contract: action.contract,
      };
    case 'SET_ADDRESS':
      return {
        ...state,
        address: action.address,
      };
    case 'SET_NUMBER_MINTED':
      return {
        ...state,
        numMinted: action.numMinted,
      };
    case 'SET_NETWORK_INFO':
      return {
        ...state,
        networkInfo: action.networkInfo,
      };
    case 'RESET_WEB3_PROVIDER':
      return initialState;

    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      throw new Error();
  }
}

export default WalletReducer;
