import React from 'react'
import styles from './ArtExample.module.css';
// import ArtExampleImage from 'media/art-example.jpg'
import MobileArtExampleImage from 'media/mobile-art-example.jpg'

export default function ArtExample() {
  return (
    <>
      <div className={styles['mobile-art-example-container']}>
        <img
          src={MobileArtExampleImage}
          className={styles['mobile-art-example']}
          alt={'Prosocialites'}
        />
      </div>

    </>

  )
}
