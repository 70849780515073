import React from 'react'
import styles from './SectionHeader.module.css';
import CSS from 'csstype';

interface ISectionHeader {
  image: any;
  alt: string;
  imageStyle: CSS.Properties;
}

export default function SectionHeader({ image, alt, imageStyle }: ISectionHeader) {
  return (
    <div className={styles['title-container']}>
      <img src={image} alt={alt} className={styles['title']} style={imageStyle} />
    </div>
  )
}
