import styles from './ClaimStore.module.css';
import CollectionItem from 'components/CollectionItem/CollectionItem';
import useCollectionItems from 'hooks/useCollectionItems';
import { useWallet } from 'hooks/useWallet';
import ConnectWalletState from 'components/Minting/ConnectWalletState/ConnectWalletState';
import { useCallback, useState } from 'react';
import MastheadImage from 'components/MastheadImage/MastheadImage';
import ComingSoonButton from 'components/ComingSoonButton/ComingSoonButton';

export default function ClaimStore() {

  // Needed for mobile
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const { state } = useWallet();
  const { address } = state;

  const { itemsOwned } = useCollectionItems();

  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);

  const handleInput = (e: any) => {
    const { value } = e.target;
    setEmail(value);
  }


  const SubmitEmail = () => {

    if (!email || itemsOwned.length === 0) return;

    const data = {
      service_id: process.env.REACT_APP_EMAIL_SERVICE_ID,
      template_id: 'template_f20d31n',
      user_id: process.env.REACT_APP_EMAIL_PUBLIC_KEY,
      template_params: {
        NFT_IDs: setTokenIds(),
        EMAIL: email,
      }
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    }

    fetch('https://api.emailjs.com/api/v1.0/email/send', requestOptions)
      .then(response => setSuccess(true))
      .catch(error => console.log('error ', error));
  }

  const setTokenIds = useCallback(() => {

    let ids = '';
    itemsOwned.forEach((item: any) => {
      ids += item.token_id + ';'
    });

    return ids;
  }, [itemsOwned])


  console.log('items ', itemsOwned);

  return (
    <div className={styles['main']}>

      <MastheadImage />

      <div className={styles['content']}>

        {!address ?
          <ConnectWalletState />
          : itemsOwned.length === 0 ?
            <div className={styles['no-items']}>
              <div className={styles['text']}>It looks like you do not own any Prosocialties</div>
              <div className={styles['coming-soon-container']}><ComingSoonButton /></div>
            </div>
            :
            <>
              {!success ?
                <div className={styles['input-container']}>
                  <div className={styles['text']}>Claim Your Store?</div>
                  <input
                    value={email}
                    maxLength={300}
                    autoFocus={false}
                    onChange={handleInput}
                    placeholder={'Email'}
                    className={styles['input']}
                  />

                  <div className={styles['submit-button']} style={{ color: 'white' }} onClick={SubmitEmail}>SUBMIT</div>

                </div>
                :
                <div className={styles['text']}>
                  Thank you! Your email has been submitted
                </div>

              }
              <div className={styles['items-container']}>
                {itemsOwned.reverse().map((item: any) => <CollectionItem item={item} key={'prosocialite-' + item?.id} />)}
              </div>

            </>
        }


      </div>


    </div>
  );
}
