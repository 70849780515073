import styles from './CollectionItem.module.css';
import ArtExample from 'media/art-large.gif';

export default function CollectionItem({ item }: { item: any }) {


  return (
    <a className={styles['container']} href={item.permalink} target={'_blank'} rel='noreferrer'>
      <div className={styles['image-container']}>
        <img 
          src={
            item.image_url ? item.image_url : ArtExample
          } 
          alt={'Prosocialite ' + item.token_id}
          className={styles['image']}
      />
      </div>

      <div className={styles['number']}>
        #{item.token_id}
      </div>

    </a>
  )
}
