


import React from 'react'
import styles from './Background.module.css';
import BackgroundGif from 'media/background-gradient.gif';

export default function Background() {
  return (
    <div className={styles['background-container']}>
      <img src={BackgroundGif} className={styles['background-gif']} alt={'Background'} />
    </div>
  )
}
