import { useEffect, useState } from 'react'
import styles from './RoadMap.module.css';

import SectionContainer from 'containers/SectionContainer/SectionContainer';
import SectionHeader from 'components/SectionHeader/SectionHeader';

import RoadMapTitle from 'media/roadmap.jpg';
// import RoadMapTitle from 'media/roadmap.png';
import RoadMapItem from 'components/RoadMapItem/RoadMapItem';
import RoadMapProgress from 'components/RoadMapProgress/RoadMapProgress';



export default function RoadMap() {

  const [curTop, setCurTop] = useState<number>(30)

  const [isLitZero, setIsLitZero] = useState<boolean>(false); //Damn that's lit
  const [isLitOne, setIsLitOne] = useState<boolean>(false); //Damn that's lit
  const [isLitTwo, setIsLitTwo] = useState<boolean>(false); //Damn that's lit
  const [isLitThree, setIsLitThree] = useState<boolean>(false); //Damn that's lit
  const [isLitFour, setIsLitFour] = useState<boolean>(false); //Damn that's lit
  const [isLitFive, setIsLitFive] = useState<boolean>(false); //Damn that's lit


  useEffect(() => {

    const width = window.innerWidth;

    if (isLitFive) {
      if (width < 992) setCurTop(2034)
      else setCurTop(2275)
    }
    else if (isLitFour) {
      if (width < 992) setCurTop(1664)
      else setCurTop(1857)
    }
    else if (isLitThree) {
      if (width < 992) setCurTop(1318)
      else setCurTop(1467)
    }
    else if (isLitTwo) {
      if (width < 992) setCurTop(770)
      else setCurTop(864)
    }
    else if (isLitOne) {
      if (width < 992) setCurTop(398)
      else setCurTop(448)
    }
    else if (isLitZero) {
      if (width < 992) setCurTop(26)
      else setCurTop(30)
    }

  }, [isLitZero, isLitOne, isLitFive, isLitFour, isLitThree, isLitTwo])


  return (
    <SectionContainer
      id={'roadmap'}
    >
      <SectionHeader
        image={RoadMapTitle}
        alt={'Road Map'}
        imageStyle={{ maxWidth: '600px' }}
      />

      <div className={styles['road-map-outside']}>
        <RoadMapProgress curTop={curTop} />
        <div className={styles['road-map-row']}>

          <RoadMapItem
            title={'A Strong Foundation'}
            subtitle={'NFTs For Good'}
            isLit={isLitZero}
            setIsLit={setIsLitZero}
          >
            <p>
              Etched and the team behind the Prosocialites have been doing charitable work in the NFT space since early 2021. In Oct. 2021, and stemming from collaborations that include <a href={'https://www.youtube.com/watch?v=ipjrwglRyIg'} className={styles['link']} target={'_blank'} rel='noreferrer'>PGA golfer Harold Varner III and Micah Johnson</a>, Peyton & Eli Manning, <a href={'https://www.youtube.com/watch?v=DSglGr7Z3Bk&t=2s&ab_channel=Etched'} target={'_blank'} rel='noreferrer'>Hockey Diversity Alliance & Matt Dumba</a>, <a href={'https://twitter.com/Pitch4Adoption/status/1465479634108178441'} target={'_blank'} rel='noreferrer'>Pitch for Adoption & Tanner Houck</a>, and Team IMPACT, we launched the <a href={'https://www.etched.co/'} className={styles['link']} target={'_blank'} rel='noreferrer'>etched.co</a> fundraising platform and have raised over $150,000 for charity to date.
            </p>

          </RoadMapItem>

          <RoadMapItem
            title={'PFP Collection'}
            subtitle={'Symbols for Optimism, Proofs of Action'}
            isLit={isLitOne}
            setIsLit={setIsLitOne}
          >
            <p>
              Prosocialites are your entry point to the Etched community. The artwork - unique, generative characters crafted by Emmy award-winning artists and which comprise over 7,500 unique assets - belongs to you, as do any and all associated IP rights. Randomized among the 7,000 Prosocialites are 20 hand-drawn one-of-ones, 304 rare hand icons, and many more which have pins corresponding to the <a href={'https://sdgs.un.org/'} className={styles['link']} target={'_blank'} rel='noreferrer'>United Nation's 17 Sustainable Development Goals</a>.
            </p>


          </RoadMapItem>

          <RoadMapItem
            title={'Charity'}
            subtitle={'Bringing Charity to the Metaverse'}
            isLit={isLitTwo}
            setIsLit={setIsLitTwo}
          >
            <p>
              Charity isn't a box to check on our roadmap. To us, the most important and exciting prospect of NFT technology is in its potential to drive greater impact and awareness toward public goods, charitable organizations, and world-improving causes.
            </p>
            <p>
              Etched's core mission is to identify and to build products and initiatives that enduringly unite philanthropy and web3 and that, in turn, drive value back to our community members.
            </p>

            <h4 className={styles['benefits-title']}>Our initiatives:</h4>
            <ul className={styles['benefits-list']}>
              <li><a href={'https://www.youtube.com/watch?v=ipjrwglRyIg&t=1s'} className={styles['link']} target={'_blank'} rel='noreferrer'>The “Etches” series</a></li>
              <li>The Meta Gala - a historic fundraising event bringing charities to the metaverse</li>
              <li><a href={'https://www.etched.co/'} className={styles['link']} target={'_blank'} rel='noreferrer'>Fundraising platform</a> - an NFT marketplace conceived of, and designed specifically for, the curation of NFT Collections tied to charitable organizations</li>
            </ul>
          </RoadMapItem>


          <RoadMapItem
            title={'7,000 Storefronts'}
            subtitle={'Custom Merchandise for Every Prosocialite'}
            isLit={isLitThree}
            setIsLit={setIsLitThree}
          >
            <p>
              Every Prosocialite NFT grants its owner access to a corresponding <a href={'https://madeinvsa.com/PROSOCIALITES'} className={styles['link']} target={'_blank'} rel='noreferrer'>fully-vertical storefront for apparel and other merchandise</a> branded with that Prosocialite's image. Owners can instantly order/sell and begin accruing royalties for high-quality, custom-branded apparel. Our cut from these sales will be 0%. Your Prosocialite, and the associated commercial rights, belong solely to you.
            </p>

          </RoadMapItem>


          <RoadMapItem
            title={'Vault Swaps'}
            subtitle={'Find Your Forever Prosocialite'}
            isLit={isLitFour}
            setIsLit={setIsLitFour}
          >
            <p>
              In these early days, we're seeing powerful examples of people forming preferences toward specific Prosocialites in ways we hadn't imagined. It's always been our goal to create PFPs that owners resonate with on a more substantive level than a superficial flex of wealth. In partnership with <a href={'https://aspenft.io/'} className={styles['link']} target={'_blank'} rel='noreferrer'>Aspen</a>, we've created a program wherein any Prosocialite can be swapped/traded with one of the ~200 in the <a href={'https://opensea.io/0xbe8d70606B9943764d34198DFeBB907bBBcED380'} className={styles['link']} target={'_blank'} rel='noreferrer'>Etched Vault</a>.
            </p>

          </RoadMapItem>


          <RoadMapItem
            title={'Bridge to Web3'}
            subtitle={'Entry Point for Major Orgs & Brands'}
            isLit={isLitFive}
            setIsLit={setIsLitFive}
          >
            <p>
              The ecosystem and existing relationships we’ve created, alongside the prosocial brand and community we are cultivating, is positioning the Prosocialites to serve as an entry point for mainstream names and charitable organizations into the space.
            </p>

          </RoadMapItem>

        </div>


      </div>

    </SectionContainer>

  )
}
