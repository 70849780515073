


import { useRef, useState } from 'react';
import styles from './ConnectWalletButton.module.css';
import { useWallet } from 'hooks/useWallet';
import { useClickOutside } from 'hooks/general.hooks';


export default function ConnectWalletButton() {

  const buttonRef = useRef(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { state, connectWallet, } = useWallet();
  const { address } = state;


  useClickOutside(buttonRef, () => setDropdownOpen(false))

  return (
    <>
      {address ?
        <div
          className={styles['connect-wallet'] + ' ' + styles['no-click']}
          onClick={() => setDropdownOpen(!dropdownOpen)}
          ref={buttonRef}
        >
          <span className={styles['address-item']}>{address[0]}{address[1]}{address[2]}{address[3]}...{address[address.length - 4]}{address[address.length - 3]}{address[address.length - 2]}{address[address.length - 1]}</span>

          {dropdownOpen &&
              <Disconnect />
          }
        </div>
        :
        <div className={styles['connect-wallet']} onClick={connectWallet}>
          CONNECT WALLET
        </div>
      }
    </>
  )
}


const Disconnect = () => {

  const { disconnectWallet } = useWallet();

  return (
    <div className={styles['disconnect']} onClick={disconnectWallet}>
      <span>Disconnect Wallet</span>
    </div>
  )

}