import React from 'react'

import styles from './AboveFold.module.css';

import MastheadImage from 'components/MastheadImage/MastheadImage';
import MastheadCopy from 'components/MastheadCopy/MastheadCopy';
import ArtExample from 'components/ArtExample/ArtExample';
import ComingSoonButton from 'components/ComingSoonButton/ComingSoonButton';




export default function AboveFold() {
  return (
    <>
      <div className={styles['above-fold']}>
        <MastheadImage />

        <div className={styles['second-row']}>
          <ArtExample />
          <MastheadCopy />
        </div>
        <ComingSoonButton />
      </div>
    </>
  )
}
