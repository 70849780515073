import React from 'react';
import styles from './DesktopMenu.module.css';

import Logo from 'components/Logo/Logo';
import { TwitterIcon } from 'icons/TwitterIcon';
import ConnectWalletButton from 'components/ConnectWalletButton/ConnectWalletButton';
import { YoutubeIcon } from 'icons/YoutubeIcon';
import { OpenSeaIcon } from 'icons/OpenSeaIcon';

import { HashLink as Link } from 'react-router-hash-link';
// import { Link } from 'react-router-dom';

export default function DesktopMenu() {
  return (
    <div className={styles['desktop-menu']}>
      <div className={styles['container'] + ' container'}>
        <div className={styles['row']}>

          <div className={styles['left-side']}>
            <Logo height={false} />

            <div className={styles['divider']}></div>

            <MenuItem text={'MINT'} anchorLocation={'/mint'} />

            <div className={styles['divider']}></div>

            <MenuItem text={'ROADMAP'} anchorLocation={'/#roadmap'} />

            <div className={styles['divider']}></div>

            <MenuItem text={'TEAM'} anchorLocation={'/#team'} />

            <div className={styles['divider']}></div>

            {/* <MenuItem text={'CONTRIBUTORS'} anchorLocation={'#mint'} />

            <div className={styles['divider']}></div> */}

            <MenuItem text={'FAQ'} anchorLocation={'/#faq'} />

          </div>


          <div className={styles['right-side']}>
            <TwitterIcon 
              fillColor={'#B9BBBA'} 
              styles={{ 
                width: '30px', 
                height: '30px',
                margin: '0 15px',
              }} 
            />
            <div className={styles['divider']}></div>
            <OpenSeaIcon
              link={'https://opensea.io/collection/theprosocialites'}
              fillColor={'#B9BBBA'}
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px', 
                height: '30px',
                margin: '0 15px'
              }}
            />
            <div className={styles['divider']}></div>
            <YoutubeIcon
              fillColor={'#B9BBBA'}
              styles={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '45px', height: '40px',
                margin: '0 10px'
              }}
              link={'https://www.youtube.com/channel/UCQjMV3g25uWdsEh1LKv9uCA/featured'}
            />
            <ConnectWalletButton />
          </div>


        </div>
      </div>
    </div>
  )
}




interface IMenuItem {
  text: string;
  anchorLocation: string;
}


const MenuItem: React.FC<IMenuItem> = ({ text, anchorLocation }) => {

  return (

    <div className={styles['menu-item']}>
      <Link className={styles['menu-item-text']} to={anchorLocation}>{text}</Link>
    </div>

  )

}