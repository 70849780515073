import styles from './Loader.module.css'

export const Loader = ({size}: {size: number}) => {
  return (

    <div className={styles['loader-parent']} style={{ height: size, width: size }}>
      <span className={styles['loader-child-1']} style={{ height: size / 2, width: size / 2 }}></span>
      <span className={styles['loader-child-2']} style={{ height: size / 3, width: size / 3 }}></span>
      <span className={styles['loader-child-3']} style={{ height: size / 6, width: size / 6 }}></span>
    </div>
  )

}