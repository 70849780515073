import CSS from 'csstype';
import ImageGif from 'media/mint-button-2.gif';
import ImageStill from 'media/mint-button-still.gif';
import { useState } from 'react';
import styles from './MintButtonGif.module.css';

//1080 x 720

interface IMintButtonGif{
  onClick: () => void;
  outsideDimensions?:  CSS.Properties;
  width?:  CSS.Properties;
}

export default function MintButtonGif({onClick}: IMintButtonGif){


  const [curImage, setCurImage] = useState(ImageGif);


  return(

    <div 
      className={styles['container']}
      onMouseOver={()=>setCurImage(ImageStill)}
      onMouseLeave={()=>setCurImage(ImageGif)}
      onClick={onClick}
    >
      <img src={curImage} className={styles['image']} alt={'Mint button'}/>
    </div>

  )

}