import Logo from 'components/Logo/Logo';
import { Hamburger } from 'icons/Hamburger';
import React, { useState, useCallback, useEffect } from 'react';
import styles from './MobileMenu.module.css';
import { HashLink as Link } from 'react-router-hash-link';
// import { Link } from 'react-router-dom';

export default function MobileMenu() {

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleNav = useCallback(() => {
    setIsOpen(!isOpen);

    if (isOpen) document.body.style.overflow = 'scroll';
    else document.body.style.overflow = 'hidden';
  }, [isOpen]);

  const closeNav = useCallback(() => {
    setIsOpen(false);
    document.body.style.overflow = 'scroll';
  }, []);


  useEffect(() => {

    let widthWatch: any;

    if (isOpen) {
      widthWatch = document.addEventListener('resize', () => {
        const width = window.innerWidth;
        if (width > 992) document.body.style.overflow = 'scroll';
      }, true);

    }

    return () => document.removeEventListener('resize', widthWatch);
  }, [isOpen]);

  return (
    <div className={styles['mobile-menu']}>

      <div className={styles['top-row']}>
        <Logo height={true} />
        <Hamburger fillColor={'rgba(101, 231, 235, 1)'} styles={{ height: '35px', width: '35px', cursor: 'pointer' }} onClick={toggleNav} />
      </div>

      <div
        className={
          styles['slide-container'] +
          ' ' +
          (isOpen ? styles['slide-nav-open'] : '')
        }
      >
        <div className={styles['bg']}></div>
        <div className={styles['slide-content']}>


          <Link
            className={styles['nav-item']}
            to={'/mint'}
            onClick={closeNav}
          >
            MINT
          </Link>

          <Link
            className={styles['nav-item']}
            to={'/#roadmap'}
            onClick={closeNav}
          >
            ROADMAP
          </Link>
          <Link
            className={styles['nav-item']}
            to={'/#team'}
            onClick={closeNav}
          >
            TEAM
          </Link>

          <Link
            className={styles['nav-item']}
            to={'/#faq'}
            onClick={closeNav}
          >
            FAQ
          </Link>

        </div>
      </div>


    </div>

  )


};
