
import { ethers } from 'ethers';
import { useWallet } from 'hooks/useWallet';
import { useEffect, useState } from 'react';
import styles from './TotalMintCounter.module.css';




//Counts total mints the project has
export default function TotalMintCounter({numMinted}: {numMinted: number}) {

  return (
    <div className={styles['counter']}>
      Minted:&nbsp;
      <span>{numMinted > 7000 ? '7,000' : numMinted.toLocaleString()} / 7,000</span>
    </div>
  )

}