import { useEffect, MutableRefObject } from "react";


//ANT HERE NEEDS TO BE FIXED
export default function useLightUp(ref: MutableRefObject<null> | any, setIsLit: any){

  // const [elmClass, setElmClass] = useState<string>('lights-off');
  // const [isLit, setIsLit] = useState<boolean>(false); //Damn that's lit

  useEffect(()=>{

    let listen: any;

    if(!ref) return;

    const checkPosition = () => {
      if (!ref.current) return false;

      var rect = ref?.current?.getBoundingClientRect();
      var elemTop = rect.top;
      var elemBottom = rect.bottom;
      let elemHeight = rect.height;



      // is partial & at least half through
      // let isVisible = 
      //   (elemTop < (window.innerHeight / 2) && elemBottom >= 0) && !(elemBottom < elemHeight / 9);

      let isVisible = (elemTop + 125 < window.innerHeight && elemBottom >= 0); //is partial
      // let isVisible = (elemTop < window.innerHeight && elemBottom >= 0) && !(elemBottom < elemHeight * 0.75);
      // let isVisible = (elemTop + 100 < window.innerHeight && elemBottom >= 0);
      // let isVisible = elemTop >= 0 && elemBottom <= window.innerHeight; // has to be full in view

      if(isVisible) setIsLit(true);
      else setIsLit(false);

    }

    listen = document.addEventListener('scroll', checkPosition);

    return () => {
      document.removeEventListener('scroll', listen, true);

    }
  }, [ref])


}





// height / 2
//when the bottom property < height / 2