import { useEffect, useState, useCallback } from 'react';

import phaseOneData from 'data/PhaseOneData';
import phaseTwoData from 'data/PhaseTwoData';
import { useWallet } from 'hooks/useWallet';

//Need to add loading here
export default function useCanMintPhases() {
  const { state, dispatch } = useWallet();

  const { address, contract } = state;

  const [canMintPhaseOne, setCanMintPhaseOne] = useState<boolean | number>(false);

  const [alreadyMintPhaseOne, setAlreadyMintPhaseOne] = useState<boolean>(false);
  
  //How many the user can mint phase 2
  const [canMintPhaseTwo, setCanMintPhaseTwo] = useState<number>(0); 
  const [canMintPublic, setCanMintPublic] = useState<number>(0);

  const [phaseFlags, setPhaseFlags] = useState({
    phaseOne: false,
    phaseTwo: false,
    phaseThree: false,
  });

  const checkAlreadyMintPhaseOne = useCallback(async () => {
    if (!address) return false;
    let hasMinted = await contract.phaseOneClaimed(address);
    setAlreadyMintPhaseOne(hasMinted);
  }, [address, contract]);

  const checkAlreadyMintPhaseTwo = useCallback(async () => {
    if (!address) return false;
    let hasMinted = await contract.mintedTokensPhaseTwo(address);
    if (hasMinted._hex) {
      let canMintNum = 3 - parseInt(hasMinted._hex, 16);
      setCanMintPhaseTwo(canMintNum);
    }
  }, [address, contract]);

  const checkPhaseActive = useCallback(async () => {
    if (!address) return false;

    const [phaseOne, phaseTwo, phaseThree] = await Promise.all([
      contract.phaseOneFlag(),
      contract.phaseTwoFlag(),
      contract.publicFlag(),
    ]);

    setPhaseFlags({
      phaseOne,
      phaseTwo,
      phaseThree,
    });
  }, [address, contract]);

  //Only if the user has minted 10 already
  const checkCanPublicMint = useCallback(async () => {
    if (!address) return false;
    let hasMinted = await contract.mintedTokens(address);
    if (hasMinted._hex) {
      let canMintNum = 10 - parseInt(hasMinted._hex, 16);
      setCanMintPublic(canMintNum)
    }
  }, [address, contract]);


  useEffect(() => {
    if (!address) return;
    dispatch({ type: 'SET_IS_LOADING', isLoading: true });

    checkPhaseActive();

    if (phaseOneData[address.toLowerCase()]) {
      checkAlreadyMintPhaseOne();
      setCanMintPhaseOne(phaseOneData[address.toLowerCase()]);
    }
    if (phaseTwoData.includes(address.toLowerCase())) {
      checkAlreadyMintPhaseTwo();
    }

    checkCanPublicMint();

    setTimeout(() => {
      dispatch({ type: 'SET_IS_LOADING', isLoading: false });
    }, 500);

    //keep eye on this in case it runs multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, dispatch]);

  return {
    canMintPhaseOne, //if user CAN mint
    canMintPhaseTwo, //number of mints possible
    canMintPublic, //number of mints possible
    alreadyMintPhaseOne,
    phaseFlags,
  };
}
