import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'; //need to fix
import Web3Modal from 'web3modal';
const INFURA_ID = '4f7df4dc05b6470f8014e90f7a4b4eae'; 

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  walletlink:{
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Prosocialites", // Required
      infuraId: INFURA_ID, // Required
      chainId: process.env.REACT_APP_CORRECT_CHAIN_ID, // Optional. It defaults to 1 if not provided
      darkMode: true // Optional. Use dark theme, defaults to false
    },
  },
  // coinbasewallet: {
  // },
  binancechainwallet: {
    package: true
  }
}


let web3Modal: any;
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    // network: 'rinkeby', // optional
    cacheProvider: true,
    providerOptions, // required
    theme: 'dark',
  })
}


export default web3Modal;