import CSS from 'csstype';
import { makeStyles, createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    item:{
      '& .change-color':{
        transitionDuration: '0.3s'
      },
      '&:hover .change-color':{
        fill: '#FF0000'
      }
    },
  })
);


interface IYoutubeIcon {
  link: string;
  styles?: CSS.Properties;
  fillColor: string;
}

export const YoutubeIcon: React.FC<IYoutubeIcon> = ({ styles, link, fillColor }) => {

  const classes = useStyles();

  return (
    <a className={classes.item} href={link} target='_blank' rel='noreferrer' style={{ ...styles, lineHeight: 0, }}>
      <svg style={{width: '100%'}} xmlns="http://www.w3.org/2000/svg" viewBox="-35.20005 -41.33325 305.0671 247.9995"><path className={'change-color'} d="M229.763 25.817c-2.699-10.162-10.65-18.165-20.748-20.881C190.716 0 117.333 0 117.333 0S43.951 0 25.651 4.936C15.553 7.652 7.6 15.655 4.903 25.817 0 44.236 0 82.667 0 82.667s0 38.429 4.903 56.85C7.6 149.68 15.553 157.681 25.65 160.4c18.3 4.934 91.682 4.934 91.682 4.934s73.383 0 91.682-4.934c10.098-2.718 18.049-10.72 20.748-20.882 4.904-18.421 4.904-56.85 4.904-56.85s0-38.431-4.904-56.85" fill={fillColor} /><path d="M93.333 117.559l61.333-34.89-61.333-34.894z" fill="#fff" /></svg>
    </a>


  )

}