import React, { useCallback } from 'react'
import styles from './ComingSoonButton.module.css';
// import ComingSoonAnimation from 'media/mint-animation.gif'
import ImageGif from 'media/mint-button-2.gif';
import { useNavigate } from 'react-router-dom';


// used to be coming soon now mint button
export default function ComingSoonButton() {

  const navigate = useNavigate();

  /**
   * Link to mint page.
   * Could be a link??
   */
  const linkToMint = useCallback(() => {
    navigate('/mint')
  }, [navigate])

  return (
    <div className={styles['coming-soon-container']}>
      <img
        src={ImageGif}
        className={styles['coming-soon']}
        alt={'Prosocialites'}
        onClick={linkToMint}
      />
    </div>
  )
}
