import styles from './Main.module.css';
import AboveFold from 'containers/AboveFold/AboveFold';
import Team from 'containers/Team/Team';
import Faq from 'containers/Faq/Faq'
import RoadMap from 'containers/RoadMap/RoadMap';

export default function Main() {

  // Needed for mobile
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  return (
    <div className={styles['main']}>

      <AboveFold />

      <RoadMap />

      <Team />

      <Faq />

    </div>
  );
}
