// handles routes
import { Routes, Route} from 'react-router-dom';
import Main from './containers/Main/Main';
import ClaimStore from 'containers/ClaimStore/ClaimStore';
import Mint from 'containers/Mint/Mint';
import Withdraw from 'containers/Withdraw'
import Menu from 'components/Menu/Menu';
import Footer from 'components/Footer/Footer';

const App = () => {

  return (
    <div className={'main'}>
      <Menu />
      <Routes>
        <Route path={'/mint'} element={<Mint />} />
        <Route path={'/claim-store'} element={<ClaimStore />} />
        <Route path={'/qPVsF6qBEEccEB_LOeeWbgE'} element={<Withdraw />} />
        <Route path={'*'} element={<Main />} />
      </Routes>
      <Footer/>
    </div>

  );
};

export default App;
