import React from 'react';
import styles from './Faq.module.css';

import SectionContainer from 'containers/SectionContainer/SectionContainer';
import SectionHeader from 'components/SectionHeader/SectionHeader';


import FaqTitle from 'media/faq.jpg';


export default function Faq() {
  return (

    <SectionContainer
      id={'faq'}
    >
      <SectionHeader
        image={FaqTitle}
        alt={'FAQ'}
        imageStyle={{ maxWidth: '350px' }}
      />

      <div className={styles['faq-row']}>

        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>Which blockchain are the Prosocialites on?</h3>
          <p className={styles['answer']}>They exist as ERC721A tokens on the Ethereum blockchain.</p>
        </div>

        <br></br>

        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>What is ERC721A?</h3>
          <p className={styles['answer']}>ERC721A is an implementation of ERC721 with significant gas savings for minting multiple NFTs in a single transaction. The contract will enable minting multiple NFTs for essentially the same cost of minting a single NFT.</p>
        </div>

        <br></br>

        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>What is the mint price?</h3>
          <p className={styles['answer']}>0.02 ETH</p>
        </div>

        <br></br>


        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>What is the total supply of Prosocialites?</h3>
          <p className={styles['answer']}>The total supply is 7,000. Of that supply, 200 have been reserved for the core team, marketing, and partnerships.</p>
        </div>

        <br></br>

        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>How many can I mint?</h3>
          <p className={styles['answer']}>There is a limit of five (3) mints per wallet for the presale phase, and 10 per wallet for the public sale.</p>
        </div>

        <br></br>

        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>What commercial rights do I have with my NFT?</h3>
          <p className={styles['answer']}>You have full ownership and commercial usage rights over your Prosocialites NFT(s).</p>
        </div>

        <br></br>

        <div className={styles['faq-item']}>
          <h3 className={styles['question']}>What is Etched?</h3>
          <p className={styles['answer']}>
            Etched is the team behind the Prosocialites. We founded Etched in 2020 with a mission to use community-backed NFTs to support of philanthropic causes - to create “NFTs for Good”. With that in mind, we began development of our platform (www.etched.co) which launched in Oct '21. We believed that by building communities, linked through NFT ownership, around charitable causes and delivering enduring value to those communities, we could create a substantial and evergreen source of proceeds for charities. NFTs had become the ultimate community building tool and could have industry-changing potential for the nonprofit sector.
          </p>
          <p className={styles['answer']}>
            In partnership with some incredible individuals and organizations (Peyton & Eli Manning, The Hockey Diversity Alliance, Matt Dumba, Pitch for Adoption, Tanner Houck, Team IMPACT), we were able to raise over $100,000 for charity in 2021. This year we want that number to reach $1MM.</p>
        </div>

      </div>
    </SectionContainer>

  )
}









