
import React from 'react';

import styles from './Logo.module.css'
import { HashLink as Link } from 'react-router-hash-link';
// import { Link } from 'react-router-dom';

export default function Logo({height}:{height: boolean}) {
  return (
    <Link to={'/#'} className={styles['logo'] + ' ' + (height ? styles['height'] : '')}>
      PROSOCIAL<span className={styles['blue']}>ITES</span>
    </Link>
  )
}
