import Image from 'media/sold-out.jpg';
import { useState } from 'react';
import styles from './MintButtonGif.module.css';


export default function SoldOutButton(){

  return(

    <div 
      className={styles['container']}
    >
      <img src={Image} className={styles['image']} alt={'Sold Out Button'}/>
    </div>

  )

}