import React from 'react'
import styles from './SectionContainer.module.css';
import Background from 'components/Background/Background';

interface ISectionContainer {
  children: React.ReactNode;
  id: string;
}

export default function SectionContainer({ children, id }: ISectionContainer) {
  return (
    <div className={styles['section-container']} id={id}>

      <div className={styles['child-container']}>
        {children}
      </div>


      <Background/>

    </div>
  )
}
