import React from 'react';
import styles from './TeamMemberItem.module.css';
import { LinkedInIcon } from 'icons/LinkedInIcon';
import { TwitterCircleIcon } from 'icons/TwitterCircleIcon';
import { CalendlyIcon } from 'icons/CalendlyIcon';

import instagramLogo from 'media/instagram-logo.png';
import chain from 'media/chain.png';

interface ITeamMemberItem {
  name: string;
  title: string;
  linkedIn?: string;
  twitter?: string;
  calendly?: string;
  generalLink?: string;
  instagram?: string;
  image: any;
  noGlow?: boolean;
}

export default function TeamMemberItem({
  name,
  title,
  linkedIn,
  twitter,
  calendly,
  generalLink,
  instagram,
  image,
  noGlow,
}: ITeamMemberItem) {
  return (
    <div className={styles['item-container']}>
      <div className={styles['item-inside']}>
        <div className={styles['image-container']}>
          <img
            src={image}
            className={
              styles['image'] + ' ' + (noGlow ? styles['no-glow'] : '')
            }
            alt={name}
          />
          <div className={styles['image-placeholder']}></div>
        </div>

        <div className={styles['member-info']}>
          <h3 className={styles['name']}>{name}</h3>
          <h4 className={styles['title']}>{title}</h4>
          <div className={styles['social-row']}>
            {generalLink && (
              <a
                href={generalLink}
                className={styles['chain-logo-container']}
                target={'_blank'}
                rel='noreferrer'
              >
                <img
                  src={chain}
                  className={styles['chain-logo']}
                  alt={'Instagram'}
                />
              </a>
            )}
            {linkedIn && (
              <LinkedInIcon
                link={linkedIn}
                styles={{ width: '40px', paddingRight: '8px' }}
              />
            )}
            {instagram && (
              <a
                href={instagram}
                className={styles['instagram-logo-container']}
                target={'_blank'}
                rel='noreferrer'
              >
                <img
                  src={instagramLogo}
                  className={styles['instagram-logo']}
                  alt={'Instagram'}
                />
              </a>
            )}
            {twitter && (
              <TwitterCircleIcon link={twitter} styles={{ width: '33px' }} />
            )}
          </div>
          {calendly && (
            <>
              <CalendlyIcon styles={{ width: '125px' }} link={calendly} />
              <p className={styles['calendly-notice']}>
                Book time with me to learn more about The Prosocialites
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
